<template>
  <svg :viewBox="viewBox" :width="width" :height="height">
    <use :href="`/assets/icons/icons.svg#${name}`" />
  </svg>
</template>

<script setup>
import { onMounted, ref } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const viewBox = ref(null);
const width = ref("auto");
const height = ref("auto");

onMounted(() => {
  if (props.name === "arrow-left" || props.name === "arrow-right") {
    viewBox.value = "0 0 14 10";
    width.value = "14";
    return;
  }

  if (props.name === "warning") {
    viewBox.value = "0 0 24 24";
    width.value = "24";
    return;
  }

  if (props.name === "home") {
    viewBox.value = "0 0 20 20";
    width.value = "20";
    return;
  }

  if (
    props.name === "check" ||
    props.name === "search" ||
    props.name === "filter"
  ) {
    viewBox.value = "0 0 16 16";
    width.value = "16";
    return;
  }

  if (
    props.name === "service-package-basic" ||
    props.name === "service-package-complete"
  ) {
    viewBox.value = "0 0 80 80";
    width.value = "80";
    return;
  }

  if (
    props.name === "chevron-left" ||
    props.name === "chevron-right" ||
    props.name === "arrow-right"
  ) {
    viewBox.value = "0 0 16 20";
    width.value = "8";
    return;
  }

  if (props.name === "close") {
    viewBox.value = "0 0 14 14";
    width.value = "14";
    return;
  }

  if (props.name === "chevron-down") {
    viewBox.value = "0 0 22 11";
    width.value = "22";
    return;
  }

  if (props.name === "chevron-down-2") {
    viewBox.value = "0 0 21 13";
    width.value = "22";
    return;
  }

  if (props.name === "chevron-right-2") {
    viewBox.value = "0 0 6 10";
    height.value = "13";
    return;
  }

  if (props.name === "location") {
    viewBox.value = "0 0 13 16";
    width.value = "13";
    return;
  }

  if (props.name === "suitcase") {
    viewBox.value = "0 0 24 24";
    height.value = "16";
    return;
  }

  if (props.name === "clock") {
    viewBox.value = "0 0 14 14";
    width.value = "14";
    return;
  }

  if (props.name === "calendar") {
    viewBox.value = "0 0 11 13";
    width.value = "11";
    return;
  }
});
</script>
