<template>
  <TransitionGroup
    name="list"
    appear
    :class="[
      'l-section-overview',
      { 'l-section-overview--1-column': isSingleColumn },
    ]"
    tag="div"
  >
    <component
      :is="getComponent(item.type)"
      v-for="(item, index) in items"
      :key="`overview-result-${index}`"
      :item="item"
      :is-featured="index === 0"
      :index="index"
      modifier="overview"
    ></component>
  </TransitionGroup>
</template>

<script setup>
defineProps({
  items: {
    required: true,
    type: Array,
  },
  isSingleColumn: {
    default: false,
    type: Boolean,
  },
});

const getComponent = (type) => {
  const componentsMap = {
    coloredCardBlock: "colored-card-block",
    newsDetail: "overview-result",
    vacancy: "vacancy-card",
    background: "overview-result",
    pressRelease: "overview-result",
    bannerBlock: "vacancy-banner",
    event: "imagery-card",
    eventExternal: "imagery-card",
  };

  return componentsMap[type];
};
</script>
